import { render, staticRenderFns } from "./ExploreWorkspace.vue?vue&type=template&id=25c4dd1e&scoped=true"
import script from "./ExploreWorkspace.vue?vue&type=script&lang=ts"
export * from "./ExploreWorkspace.vue?vue&type=script&lang=ts"
import style0 from "./ExploreWorkspace.vue?vue&type=style&index=0&id=25c4dd1e&prod&lang=scss"
import style1 from "./ExploreWorkspace.vue?vue&type=style&index=1&id=25c4dd1e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25c4dd1e",
  null
  
)

export default component.exports