import { render, staticRenderFns } from "./ForbiddenBanner.vue?vue&type=template&id=23b6aa26&scoped=true"
import script from "./ForbiddenBanner.vue?vue&type=script&lang=js"
export * from "./ForbiddenBanner.vue?vue&type=script&lang=js"
import style0 from "./ForbiddenBanner.vue?vue&type=style&index=0&id=23b6aa26&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23b6aa26",
  null
  
)

export default component.exports