import { render, staticRenderFns } from "./TextAreaField.vue?vue&type=template&id=1ca1c282&scoped=true"
import script from "./TextAreaField.vue?vue&type=script&lang=ts"
export * from "./TextAreaField.vue?vue&type=script&lang=ts"
import style0 from "./TextAreaField.vue?vue&type=style&index=0&id=1ca1c282&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ca1c282",
  null
  
)

export default component.exports